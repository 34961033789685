import {Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
export function Numbers(props) {
    const {numbers, title} = props;
    return (
        <div className = "mt-3 rounded shadow-sm" style={{backgroundColor: "Lavender"}}>
            <h3 className="text-center"> {title}</h3>
            <Row> {numbers.map(n=><Col className="bg-primary m-1">{n}</Col>)} </Row>
        </div>
    );
}


Numbers.propTypes = {
    numbers: PropTypes.arrayOf(PropTypes.number).isRequired,
    title: PropTypes.string.isRequired,
};