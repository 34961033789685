import {Numbers} from "../components/Numbers";

export function NumbersPage(props) {
    const {numbers} = props;
    const doubleNumbers = numbers.map(n => n * 2);
    return (
        <div className={"mx-3"}>
            <Numbers numbers={numbers} title="alle nummers"/>
            <Numbers numbers={numbers.filter(n => n > 6)} title="getallen>6"/>
            <Numbers numbers={doubleNumbers} title="getallen*2"/>
        </div>
    );
}