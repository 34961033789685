import {Row} from "react-bootstrap";
import PropTypes from "prop-types";

export function Cars(props) {
    const {cars, title} = props;
    return (
        <div>
            <h2>{title}</h2>
            <Row>
                {cars.map((c) => (
                    <Car key={c.id} car={c}/>
                ))}
            </Row>
        </div>
    );
}

function Car(props) {
    const {car} = props;
    return (
        <div>
            {car.name && <strong>{car.name}</strong>}
            {car.brand && <p>Merk: {car.brand}</p>}
            {car.type && <p>Type: {car.type}</p>}
            {car.note && <p>Opm: {car.note}</p>}
            {car.color && <p>Kleur: {car.color}</p>}
        </div>
    );
}

Cars.propTypes = {
    cars: PropTypes.array,
    title: PropTypes.string,
};
