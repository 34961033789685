export const PRODUCTS_DATA = [
    {name: "cola", price: 1},
    {name: "water", price: 1, note:"plat of bruisend"},
    {name: "bier ", price: 1, size: 25},
    {name: "bier groot ", price: 1, size: 33},
    {name: "wijn", price:2, note:"rood, wit of rosé"},
    {name: "cava", price:3}
];
export const NUMBER_DATA = [1, 3, 5, 2, 6, 7, 34, 12, 66, 98, 11, 2];

export const CAR_DATA = [
    {
        id: 1,
        name: "mijn eerste auto",
        brand: "peugeot",
        type: "204",
        color: "blauw",
    },
    {
        id: 2,
        name: "auto van mijn zus",
        brand: "fiat",
        type: "punto",
        color: "geel",
        note: "die ging altijd kapot"
    },
    {id: 3, name: "die van mijn pa", brand: "volvo", color: "zwart"},
    {id: 4, name: "geitje", brand: "citroën",},
    {id: 5, name: "auto1", brand: "Peugeot", type: "505", color: "grijs"},
    {id: 6, name: "auto2", brand: "Fiat", type: "Panda"},
    {id: 7, name: "auto3", brand: "Fiat", type: "Tipo", color: "groen"},
    {id: 8, name: "auto4", brand: "Citroën", type: "C4", color: "rood"},
    {id: 9, name: "auto5", brand: "Fiat", type: "Panda", color: "rood"}
];
