import * as PropTypes from "prop-types";
const sizeStyle = {
    color: 'blue',
    fontSize: '75%'
};

const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '2em',
    padding: '.5em',
    width: '15%'
};

function ProductSize(props) {
    return <span style={sizeStyle}>({props.product.size}cl)</span>;
}

ProductSize.propTypes = {product: PropTypes.any};
function ProductNote(props) {
    return  <div>
                <span style={sizeStyle}> {props.product.note}
                </span>
            </div>;
}

ProductNote.propTypes = {product: PropTypes.any};
export function MenuProduct(props) {
    const {product} = props;
    if(!product.name) return null;
    return (
             <div style={containerStyle}>
             <div>
                {product.name}
                 {product.size ? <ProductSize product={product}/> :null}
                 {product.note ? <ProductNote product={product}/> : null}
            </div>
            <div>
                {product.price} €
            </div>
        </div>
        )
}
MenuProduct.propTypes =
    {
        product : PropTypes.shape({
            name: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            note: PropTypes.string,
            size: PropTypes.number
        })
    }