const PICTURES_DATA = [
    {
        id: 1,
        credit: "https://www.vier-voeters.nl/campagnes-en-themas/themas/gezelschapsdieren/een-hond-als-huisdier",
        author: "Hond",
        name: "hond.jpg"
    }
];

function Picture(props) {
    const {picture} = props;

    return (<>
        <img src={`images/${picture.name}`}
             alt={`${picture.name}`}
             width="100%"
             style={{margin: "2vw auto 0 auto"}}/>
        by <a href={picture.credit}> {picture.author}</a>
    </>);
}

export function PicturesPage() {
    return (
        <div style={{margin: "0 2vw"}}>
            <h1>Pictures</h1>
            {PICTURES_DATA.map(p => <Picture key={p.id} picture={p}/>)}
        </div>
    );
}
