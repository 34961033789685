import 'normalize.css';
import 'react-tabs/style/react-tabs.css';
import './App.css';
import {CAR_DATA, NUMBER_DATA, PRODUCTS_DATA} from "./data/data";
import {MenuCardPage} from "./pages/MenuCardPage";
import {PicturesPage} from "./pages/PicturesPage";
import {NumbersPage} from "./pages/NumbersPage";
import {Tabs, TabList, Tab, TabPanel} from "react-tabs";
import {CarsPage} from "./pages/CarsPage";

function App() {
    return (
    <Tabs>
            <TabList>
                <Tab>Menu</Tab>
                <Tab>Pictures</Tab>
                <Tab>Numbers </Tab>
                <Tab>Cars </Tab>
            </TabList>
            <TabPanel>
                <MenuCardPage products={PRODUCTS_DATA}/>
            </TabPanel>
            <TabPanel>
                <PicturesPage/>
            </TabPanel>
            <TabPanel>
                <NumbersPage numbers={NUMBER_DATA}/>
            </TabPanel>
            <TabPanel>
                <CarsPage cars={CAR_DATA}/>
             </TabPanel>
        </Tabs>
    );
}

export default App;
